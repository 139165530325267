.home-page {
  text-align: center;
  z-index: 1;
  button {
    margin: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 250px;
    display: block;
  }
}
