.header {
  position: relative;
  height: 95px;
  z-index: 1;
  .version {
    margin-left: 20px;
    font-family: monospace;
  }
  .title {
    display: inline-block;
    margin: 20px;
    margin-top: 12px;
    vertical-align: middle;
    margin-bottom: 0px;
  }
  .controls {
    .wallet {
      border-radius: 0 0 0 20px;
      display: inline-block;
      border-left: solid;
      border-bottom: solid;
      border-radius: 0 0 0 20px;
      width: 150px;
      height: 110px;
      position: absolute;
      top: 0px;
      right: 0px;
      .connect {
        border: none;
        border-bottom:solid;
        border-radius: 0 0 0 0px;
        width: 100%;
        height: 50%;
      }
      .connect-walletconnect{
        border-radius: 0 0 0 20px;
        border:none;
      }
      .info {
        width: 100%;
        height: 100%;
        padding: 5px 20px;
        font-size: 0.8em;
        .account,
        .network {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .color-mode {
      position: absolute;
      top: 108px;
      right: 0px;
      border-radius: 0 0 0 20px;
      border-left: solid;
      background-color: none;
      border-bottom: solid;
    }
    .network-picker {
      position: absolute;
      top: 0px;
      right: 147px;
      padding: 10px;
      font-size: 0.8em;
      border-radius: 0 0 0px 20px;
      border: solid;
      border-top: none;
      .network-tooltip {
        position: relative;
        margin-left: 5px;
        &:hover .network-tooltip-text {
          visibility: visible;
        }
        .network-tooltip-text {
          position: absolute;
          max-width: 50vw;
          width: 200px;
          padding: 10px;
          padding-left: 15px;
          top: 14px;
          left: -185px;
          white-space: normal;
          text-align: left;
          visibility: hidden;
          border: solid;
        }
      }
    }
  }
}
