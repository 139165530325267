body,
html,
#root {
  min-height: 100vh;
  position: relative;
}

#root {
  padding-bottom: 30vh;
}

hr {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

h1,
h2,
h3 {
  font-weight: lighter !important;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.horizontal-center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.both-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
